export const menuList = [{
  header: 'REPORTS',
  submenu: [{
    name: "Generate Reports",
    url: "/generate-reports",
    access: "REPORTS"
  }]
}, {
  header: 'SETTINGS',
  submenu: [{
    name: "My Account",
    url: "/my-account",
    access: "MY_ACCOUNT"
  },
  {
    name: "My Commission",
    url: "/my-commission",
    access: "MY_ACCOUNT"
  }]
}, {
  header: 'MARKETING',
  submenu: [{
    name: "Referral links",
    url: "/referral-links",
    access: "AFFILIATE_LINKS"
  }]
}];