import React, { Component } from 'react';
import { Panel, Button } from "react-bootstrap";
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Column, Row } from 'simple-flexbox';

import Dropdowns from './dropdowns';
import DashBoardOverallPerformanceComponent from "./dashBoardPanelComponents/DashBoardOverallPerformanceComponent";
import DashBoardAgentCommissionsComponent from "./dashBoardPanelComponents/DashBoardAgentCommissionsComponent";
import DashBoardMerchantCommissionsComponent from "./dashBoardPanelComponents/DashBoardMerchantCommissionsComponent";
import DashBoardPendingReferralsComponent from "./dashBoardPanelComponents/DashBoardPendingReferralsComponent";
import DashBoardPendingAgentsComponent from "./dashBoardPanelComponents/DashBoardPendingAgentsComponent";
import LastUpdateComponent from "./dashBoardPanelComponents/LastUpdateComponent";
import Select from "../Select";

import { DASHBOARD_OVERALL_PERFORMANCE_EVENT, DASHBOARD_AGENT_COMMISSIONS_EVENT,
  DASHBOARD_MERCHANT_COMMISSIONS_EVENT, DASHBOARD_PENDING_REFERRALS_EVENT,
  DASHBOARD_PENDING_AGENTS_EVENT } from '../../../actions/types';

import '../../../assets/css/t365/apiData.scss';
import '../../../assets/css/trustbucks/apiData.scss';
import Calendar from '../../../assets/images/calendar.png';

import * as actions from '../../../actions';
import { crudActions } from "../../../services/crudActions";
import { backEndBaseUrl, whiteLabel } from "../../../config";
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "../../../utils/utils.js";
const combineReducers =  require('../../../reducers/index');

class APIData extends Component {
  state = {
    controls: {
		  timePeriod: {
        value:'thisMonth'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    dropdowns: {
      periods: Dropdowns.periods
    },
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(2).from,
      toDateTimeStamp: this.getPeriod(2).to
    },
    dateFieldDisabled: true,
    agent: {},
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),

    errorMessage: false,
  };

  subscribed = true;
  thisMonth = true;
  timeout = 250;
  reconnect = true;
  connectTimeout = null;
  heartbeatInterval = null;
  heartbeatMessage = "ping";

  componentDidMount() {
    this.loadRestData().then(
      () => {
        // this.connect();
      }
    );
    
  };

  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }

    this.reconnect = false;
    clearInterval(this.heartbeatInterval);
    clearTimeout(this.connectTimeout);
  };

  connect = () => {
    const user = JSON.parse(localStorage.user);
    const ws = new WebSocket(
      (window.location.protocol === "https:" ? "wss" : "ws") + "://" + backEndBaseUrl.split("/")[2] + "/agent-dashboard-ws", //?token=" + user.accessToken
      //possible solution for putting token in headers
      [user.accessToken]
    );
    const that = this;

    ws.onopen = () => {
      console.log("WebSocket connected"); // eslint-disable-line no-console

      this.setState({
        ws: ws
      });

      ws.send(
        JSON.stringify({
          subscribed: this.subscribed
        })
      );

      const heartbeatMessage = this.heartbeatMessage;

      if (this.heartbeatInterval === null) {
        this.heartbeatInterval = setInterval(function() {
          ws.send(heartbeatMessage);
        }, 60000);
      }

      that.timeout = 250;
      clearTimeout(this.connectTimeout);
    };

    ws.onclose = e => {
      console.log( // eslint-disable-line no-console
        `Socket is closed. Reconnect will be attempted in ${(that.timeout * 2) / 1000} second.`,
        e
      );

      that.timeout = that.timeout * 2;
      this.connectTimeout = setTimeout(this.check, Math.min(10000, that.timeout));
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    };

    ws.onmessage = evt => {
      if (evt.data === "pong") {
        return;
      }
      const message = JSON.parse(evt.data);
      this.updateDashboard(message);
    };

    ws.onerror = err => {
      console.error( // eslint-disable-line no-console
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  };

  check = () => {
    const { ws } = this.state;
    if ((!ws || ws.readyState === WebSocket.CLOSED) && this.reconnect) {
      // this.connect();
    }
  };

  updateDashboard = (data) => {
    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY')
    });

    switch(data.type) {
    case 'AGENTS_COMMISSION':
		    combineReducers.default.dispatch({type: DASHBOARD_AGENT_COMMISSIONS_EVENT, socket: true, payload: data.data});
      break;
    case 'MERCHANT_COMMISSION':
      combineReducers.default.dispatch({type: DASHBOARD_MERCHANT_COMMISSIONS_EVENT, socket: true, payload: data.data});
      break;
    case 'OVERALL_PERFORMANCE_BY_PERIOD':
      combineReducers.default.dispatch({type: DASHBOARD_OVERALL_PERFORMANCE_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'PENDING_REFERRALS':
      combineReducers.default.dispatch({type: DASHBOARD_PENDING_REFERRALS_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    case 'PENDING_AGENTS':
      combineReducers.default.dispatch({type: DASHBOARD_PENDING_AGENTS_EVENT, socket: true, payload: data.data});
      //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, socket: true, payload: data.data.purchaseMethodsPie});
      break;
    default:
      console.log('Wrong type ' + data.type); // eslint-disable-line no-console
    }
  };

  loadRestData = () => {
    return crudActions.get(`v1/myaccount/me`).then(
      clientData => {
        if (clientData) {
          this.setState({
            agent: {
              id: clientData.id,
              value: clientData.name,
              label: clientData.name
            }
          }, this.loadDashboardData);
        }
      }
    ).catch(e => {
      this.props.showMessage(e.message);
    });
  };

  loadDashboardData = () => {
    const { agent, timeStamp } = this.state;

    const fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().format("DD.MM.YYYY HH:mm:ss");
    const toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().format("DD.MM.YYYY HH:mm:ss");

    this.setState({
      lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),
      errorMessage: false
    });

    combineReducers.default.dispatch({ type: "LOADING" });
    if (agent && agent.id) {
      crudActions.get(`v1/agent/dashboard/overall-by-period?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_OVERALL_PERFORMANCE_EVENT, socket: false, payload: data});
        //combineReducers.default.dispatch({type: DASHBOARD_PIE_EVENT, sse: false, payload: data.purchaseMethodsPie});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v1/agent/dashboard/commissions/agents?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_AGENT_COMMISSIONS_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v1/agent/dashboard/commissions/merchants?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_MERCHANT_COMMISSIONS_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v1/agent/dashboard/pending-referrals?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PENDING_REFERRALS_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v1/agent/dashboard/pending-agents?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PENDING_AGENTS_EVENT, socket: false, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });
    }
  };

  onChangeFilter = (e) => {
    // const { ws } = this.state;
    e.preventDefault();
    this.subscribed = this.thisMonth;
    // ws.send(
    //   JSON.stringify({
    //     subscribed: this.subscribed
    //   })
    // );

    this.loadDashboardData();
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().subtract(1, 'day').hour(23).minute(59).second(59)
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59)
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth()).hour(23).minute(59).second(59)
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().hour(23).minute(59).second(59)
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31).hour(23).minute(59).second(59)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().hour(0).minute(0).second(0),
        to: moment().utc()
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = {...this.state.timeStamp};
    const controls = {...this.state.controls};
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id <= 5; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = { ...this.state.controls };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp, isSubmitting: false });
  };

  isDateValid = (current, state) => {
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;

    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  render() {
    const controls = {...this.state.controls};
    const { timeStamp, isSubmitting, dropdowns, dateFieldDisabled, lastUpdate } = this.state;
    const thisMonth = this.thisMonth;

    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module apidata` } vertical='start'>
        <Column flexGrow={ 1 }>
          <LastUpdateComponent lastUpdate={ lastUpdate }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading className={ whiteLabel }>
                  <Panel.Title> TIME PERIOD</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <form>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' className="filter-row">
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>Time Period</label>
                          <Select id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ dropdowns.periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column first-datepicker" alignSelf='start'>
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            disabled={ dateFieldDisabled }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column" alignSelf='start'>
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            disabled={ dateFieldDisabled }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <Button
                            type="button"
                            className={ whiteLabel === "trustbucks" ? `${whiteLabel} btn defaultBtn` : "starmids btn defaultBtn" }
                            disabled={ isSubmitting }
                            onClick={ (e) => this.onChangeFilter(e) }>
                            Filter
                          </Button>
                        </Column>
                      </Row>
                    </form>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardMerchantCommissionsComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardAgentCommissionsComponent thisMonth={ thisMonth }/>
              </Column>
            </Row>

            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardOverallPerformanceComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPendingReferralsComponent thisMonth={ thisMonth }/>
              </Column>
            </Row>
            <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <DashBoardPendingAgentsComponent thisMonth={ thisMonth }/>
              </Column>
              <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                <span/>
              </Column>
            </Row>
          </Row>
        </Column>
      </Row>
    );
  }
}

export default connect(null, actions)(APIData);
