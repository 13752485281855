import BANKPAY from '../../assets/images/payment-methods/BANKPAY.png';
import QUICKPAY from '../../assets/images/payment-methods/QUICKPAY.png';
import UNIONPAY_INTERNATIONAL from '../../assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY_INTERNATIONAL_EURO from '../../assets/images/payment-methods/UNIONPAY_INTERNATIONAL.png';
import UNIONPAY from '../../assets/images/payment-methods/UNIONPAY.png';
import ALIPAY from '../../assets/images/payment-methods/ALIPAY.png';
import BANK_TRANSFER from '../../assets/images/payment-methods/BANK_TRANSFER.png';
import CREDIT_CARD from '../../assets/images/payment-methods/CREDIT_CARD.png';
import starmidsDashboard from '../../assets/images/dashboardIcon.png';
import trustbucksDashboard from '../../assets/images/trustbucks/dashboardIcon.png';
import t365Dashboard from '../../assets/images/t365/dashboardIcon.png';
import starmidsMyAccount from '../../assets/images/myaccount.png';
import trustbucksMyAccount from '../../assets/images/trustbucks/myAccountIcon.png';
import t365MyAccount from '../../assets/images/t365/myAccountIcon.png';
import starmidsUserManagement from '../../assets/images/user-management.png';
import trustbucksUserManagement from '../../assets/images/trustbucks/userManagementIcon.png';
import t365UserManagement from '../../assets/images/t365/userManagementIcon.png';
import trustbucksLogo from '../../assets/images/trustbucksLogo.png';
import tlapayLogo from '../../assets/images/TlapayLogo.png';
import fxmbLogo from '../../assets/images/fxmbLogo.png';
import t365Logo from '../../assets/images/t365Logo.png';
import starmidsLogo from '../../assets/images/starmids-agents.png';
import t365Commission from '../../assets/images/t365/payouts.png';
import trustbucksСommission from '../../assets/images/trustbucks/payouts.png';
import starmidsCommission from '../../assets/images/payouts.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BANKPAY,
  QUICKPAY,
  UNIONPAY_INTERNATIONAL,
  UNIONPAY_INTERNATIONAL_EURO,
  ALIPAY,
  UNIONPAY,
  BANK_TRANSFER,
  CREDIT_CARD,
  starmids: {
    dashboard: starmidsDashboard,
    myaccount: starmidsMyAccount,
    userManagement: starmidsUserManagement,
    commission: starmidsCommission,
    logo: starmidsLogo,
    text: "STARMIDS AGENTS"
  },
  trustbucks: {
    dashboard: trustbucksDashboard,
    myaccount: trustbucksMyAccount,
    userManagement: trustbucksUserManagement,
    commission: trustbucksСommission,
    logo: trustbucksLogo,
    text: "TRUSTBUCKS AGENTS"
  },
  tlapay: {
    logo: tlapayLogo,
    text: "TLAPAY AGENTS"
  },
  fxmb: {
    logo: fxmbLogo,
    text: "FXMD AGENTS"
  },
  t365: {
    dashboard: t365Dashboard,
    myaccount: t365MyAccount,
    userManagement: t365UserManagement,
    commission: t365Commission,
    logo: t365Logo,
    text: "Transact 365 AGENTS"
  },
  "88mids": {
    dashboard: starmidsDashboard,
    myaccount: starmidsMyAccount,
    userManagement: starmidsUserManagement,
    commission: starmidsCommission,
    logo: undefined,
    text: "88MIDS Agent Back Office"
  }
};