import React, { Component } from 'react';
import { Panel } from "react-bootstrap";
import { Row, Column } from 'simple-flexbox';

import ComponentHeader from "../../componentHeader";
import MyCommissionIcon from '../../../components/icons/methods';

import '../../../assets/css/myAccount.css';

import { crudActions } from "../../../services/crudActions";
import Table from "../Table";
import { whiteLabel } from "../../../config";
import Alert from "../Alert";

class MyCommission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      columns: [{
        value: "currency",
        label: "Currency"
      }, {
        value: "method",
        label: "Method",
      }, {
        value: "midId",
        label: "MID ID",
        isNumeric: true,
      }, {
        value: "agentFee",
        label: "Agent Fee",
        isNumeric: true,
      }],

      buy: [],
      payout: [],
      showAlert: false
    };
  }

  componentDidMount() {
    crudActions.get(`v1/agent/commissions`).then(
      (clientData) => {
        if (clientData) {
          this.setState({
            buy: clientData.buy,
            payout: clientData.payout,
            isLoading: false
          });
        }
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  tableData = (type) => {
    return this.state[type];
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  render() {
    const { columns, showAlert, alertType, alertMessage } = this.state;

    return (
      <Row flexGrow={ 1 } className='module apidata my-account' vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="My Commission"
            img={ MyCommissionIcon[whiteLabel].commission }
          />
          <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ {width: '100%'} }>
            <Column className="table-column" flexGrow={ 1 } vertical='start' style={ {padding: 15, width: '100%'} }>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    AGENT COMMISSION
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {
                    <div>
                      <div key="buy">
                        <h4> PAYIN </h4>
                        <div className="panel-content">
                          <Table
                            columns={ columns }
                            headerColor={ whiteLabel === "trustbucks" ? "#263e0f" : whiteLabel === "t365" ? "#25282a" : "#223679" }
                            textColor={ "#FFFFFF" }
                            data={ this.tableData("buy") }
                            isStriped={ false }
                          />
                        </div>
                      </div>
                      <div key="payout">
                        <hr/>
                        <h4> PAYOUT </h4>
                        <div className="panel-content">
                          <Table
                            columns={ columns }
                            headerColor={ whiteLabel === "trustbucks" ? "#263e0f" : whiteLabel === "t365" ? "#25282a" : "#223679" }
                            textColor={ "#FFFFFF" }
                            data={ this.tableData("payout") }
                            isStriped={ false }
                          />
                        </div>
                      </div>
                    </div>
                  }
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}
      </Row>
    );
  }
}

export default MyCommission;
