import React, { Component } from 'react';
import { Panel, Button } from "react-bootstrap";
import { Row, Column } from 'simple-flexbox';
import SweetAlert from 'react-sweetalert2';

import ComponentHeader from "../../componentHeader";
import Form from "../../../core/Form";
import MyAccountIcon from '../../../components/icons/methods';
import Spinner from '../../Spinner';

import '../../../assets/css/myAccount.css';
import localization from '../../../assets/lang/language';

import { whiteLabel } from '../../../config';
import { crudActions } from "../../../services/crudActions";

class MyAccount extends Component {
  state = {
    accountForm: new Form({
      name: "",
      phone: "",
      skype: "",
      currentPassword: null,
      newPassword: null
    }, [{
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "phone",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "skype",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "currentPassword",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "newPassword",
      type: "isString",
      rules: {
        required: false
      }
    }]),

    username: "",
    role: "",
    whiteLabels: "",
    merchants: "",
    merchantCommission: null,
    agentCommission: null,
    whiteLabelCommission: null,

    confirmPassword: "",
    confirmPasswordTouched: false,
    showSuccess: false,
    showError: false,
    errorMessage: "",
    successMessage: "",
    isLoading: true,

    //temporary
    currentLanguage: "en"
  };

  componentDidMount() {
    const { accountForm } = this.state;

    crudActions.get(`v1/myaccount`).then(
      (accountData) => {
        if (accountData) {
          this.setState({
            accountForm: Object.assign(accountForm, {
              name: accountData.name,
              phone: accountData.phone,
              skype: accountData.skype
            }),
            username: accountData.username,
            role: accountData.role,
            whiteLabels: accountData.whiteLabels.join(", "),
            merchants: accountData.merchants.join(", "),
            agentCommission: accountData.agentCommission,
            merchantCommission: accountData.merchantCommission,
            whiteLabelCommission: accountData.whiteLabelCommission,
            isLoading: false
          });
        }
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showError: true,
            errorMessage: err.message
          });
        }
      }
    );
  };

  onValueChange = (event, fieldName) => {
    const { accountForm } = this.state;
    this.setState({
      accountForm: Object.assign(accountForm, {
        [fieldName]: event.target.value
      })
    });
  };

  onConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
      confirmPasswordTouched: true
    });
  };

  arePasswordsIdentical = () => {
    const { accountForm, confirmPassword } = this.state;

    if (!accountForm.currentPassword && !accountForm.newPassword) {
      return true;
    }

    return accountForm.newPassword === confirmPassword;
  };

  submitAccountSettings = () => {
    const { accountForm } = this.state;
    const isFormValid = accountForm.isFormValid();
    this.setState({
      accountForm: accountForm,
      confirmPasswordTouched: true
    });

    if (isFormValid && this.arePasswordsIdentical()) {
      crudActions.put(`v1/myaccount`, Object.assign(accountForm.data(), { fullName: accountForm.name })).then(
        () => {
          this.setState({
            showSuccess: true,
            successMessage: "Your data was successfully updated."
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showError: true,
              errorMessage: err.message
            });
          }
        }
      );
    }
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
      errorMessage: "",
      successMessage: "",
    });
  };

  render() {
    const { accountForm, confirmPassword, confirmPasswordTouched, username, isLoading, currentLanguage,
      role, whiteLabels, merchants, showSuccess, showError, errorMessage, successMessage,
      agentCommission, merchantCommission, whiteLabelCommission} = this.state;
    const language = localization[currentLanguage];

    return (
      <Row flexGrow={ 1 } className='module apidata my-account' vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="My Account"
            img={ MyAccountIcon[whiteLabel].myaccount }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    USER INFO
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <form autoComplete="off">
                    <input type="password" autoComplete="new-password" style={ { display: "none" } }/>
                    { isLoading ? <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div> : <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Full Name </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('name') ? 'error-field' : '') }
                              value={ accountForm.name || '' }
                              onChange={ (e) => this.onValueChange(e, "name") }/>
                            { accountForm.errors.has('name') && <p className="error-message"> { accountForm.errors.get('name') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Phone </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('phone') ? 'error-field' : '') }
                              value={ accountForm.phone || '' }
                              onChange={ (e) => this.onValueChange(e, "phone") }/>
                            { accountForm.errors.has('phone') && <p className="error-message"> { accountForm.errors.get('phone') } </p> }
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Skype </label>
                            <input
                              className={ "form-control " + (accountForm.errors.has('skype') ? 'error-field' : '') }
                              value={ accountForm.skype || '' }
                              onChange={ (e) => this.onValueChange(e, "skype") }/>
                            { accountForm.errors.has('skype') && <p className="error-message"> { accountForm.errors.get('skype') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Email/username </label>
                            <input
                              className="form-control"
                              disabled
                              value={ username || '' }/>
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <h5 className="change-password-title"> CHANGE PASSWORD </h5>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Current Password </label>
                            <input
                              type="password"
                              className={ "form-control " + (accountForm.errors.has('currentPassword') ? 'error-field' : '') }
                              value={ accountForm.currentPassword || '' }
                              onChange={ (e) => this.onValueChange(e, "currentPassword") }/>
                            { accountForm.errors.has('currentPassword') && <p className="error-message"> { accountForm.errors.get('currentPassword') } </p> }
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> New Password </label>
                            <input
                              type="password"
                              className={ "form-control " + (accountForm.errors.has('newPassword') ? 'error-field' : '') }
                              value={ accountForm.newPassword || '' }
                              onChange={ (e) => this.onValueChange(e, "newPassword") }/>
                            { accountForm.errors.has('newPassword') && <p className="error-message"> { accountForm.errors.get('newPassword') } </p> }
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='start' className="input-column">
                            <label> New Password Again </label>
                            <input
                              type="password"
                              className={ "form-control " + (accountForm.errors.has('confirmPassword') ? 'error-field' : '') }
                              value={ confirmPassword || '' }
                              onChange={ (e) => this.onConfirmPasswordChange(e) }/>
                            { !this.arePasswordsIdentical() && confirmPasswordTouched && <p className="error-message">
                              { !confirmPassword ? "Please, confirm the password."  : "Passwords are not identical." } </p>}
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <h5 className="change-password-title"> ACCESS </h5>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> User Level </label>
                            <input
                              className="form-control"
                              disabled
                              value={ language.roles[role] || '' }/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> White Label </label>
                            <input
                              className="form-control"
                              disabled
                              value={ whiteLabels || 'No white label.' }/>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Merchant </label>
                            <input
                              className="form-control"
                              disabled
                              value={ merchants || 'No merchant.' }/>
                          </Column>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <span/>
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <h5 className="change-password-title"> COMMISSION </h5>
                        </Column>
                      </Row>
                      <Row wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Agent Commission </label>
                            <input
                              type="text"
                              disabled={ true }
                              className={ "form-control " }
                              value={ agentCommission }/>
                            <span className="percent-sign-complex"> % </span>
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Merchant Commission </label>
                            <input
                              type="text"
                              disabled={ true }
                              className={ "form-control " }
                              value={ merchantCommission }/>
                            <span className="percent-sign-complex"> % </span>
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> White Label Commission </label>
                            <input
                              type="text"
                              disabled={ true }
                              className={ "form-control " }
                              value={ whiteLabelCommission }/>
                            <span className="percent-sign-complex"> % </span>
                          </Column>
                          <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                            <Button
                              className="btn defaultBtn"
                              onClick={ this.submitAccountSettings }>
                            Save
                            </Button>
                          </Column>
                        </Row>
                      </Row>
                    </div> }
                  </form>
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>
        <SweetAlert
          show={ showSuccess }
          title="Success"
          icon="success"
          confirmButtonColor={ whiteLabel === "trustbucks" ? "#263e0f" : whiteLabel === "t365" ? "#25282a" : "#187EED" }
          text={ successMessage }
          onConfirm={ this.onConfirm }
        />
        <SweetAlert
          show={ showError }
          title="Error"
          icon="error"
          confirmButtonColor={ whiteLabel === "trustbucks" ? "#263e0f" : whiteLabel === "t365" ? "#25282a" : "#187EED" }
          text={ errorMessage }
          onConfirm={ this.onConfirm }
        />
      </Row>
    );
  }
}

export default MyAccount;
